import { Edit } from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  DownloadGriegConnectPresentation,
  LineChartValuesDto,
  PowerpointReportDto,
  TableValuesDto,
} from "../api/DownloadKeyMetricsPresentation";
import { GetRevenuePerProduct, Product } from "../api/GetRevenue";
import {
  AccountType,
  GetAccountingStructure,
  GetTrialBalance,
  TransactionQuery,
  TrialBalanceAccountDto,
} from "../api/GetTrialBalance";
import { PowerpointIcon } from "../icons/PowerpointIcon";
import { formatNumberInThousands } from "../utils/formatting";
import {
  getCurrentAndFirstPeriodOfYear,
  getPreviousYearPeriods,
} from "../utils/timePeriods";
import {
  calculateAmountByPeriodAndAccount,
  calculateEBITByPeriod,
  calculatePretaxIncomeByPeriod,
  generatePeriodsForYear,
  hasValuesForAccountTypeOrCaption,
} from "../utils/trialBalanceCalculations";
import EditPowerpointModal from "./EditPowerpointModal";
import LineChart from "./LineChart";
import LineChartLoader from "./LineChartLoader";
import SkeletonTable from "./SkeletonTable";
import { getLatestQuarter } from "../pages/admin/QuarterlyReporting";

type ProductValues = {
  values: number[];
  name: string;
  total: number;
};

const RevenueTable: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [distinctProducts, setDistinctProducts] = useState<string[]>([]);
  const [distinctPeriods, setDistinctPeriods] = useState<number[]>([]);
  const [distinctAreas, setDistinctAreas] = useState<string[]>([]);
  const [productValues, setProductValues] = useState<ProductValues[]>([]);
  const [vendorValues, setVendorValues] = useState<ProductValues[]>([]);
  const [distinctVendors, setDistinctVendors] = useState<string[]>([]);
  const [detailsMode, setDetailsMode] = useState<"vendor" | "product">(
    "product"
  );

  const [open, setOpen] = useState(false);
  const [report, setReport] = useState<PowerpointReportDto | null>(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSave = (updatedReport: PowerpointReportDto) => {
    setReport(updatedReport);
  };

  const [currentData, setCurrentData] = useState<TrialBalanceAccountDto[]>([]);
  const [previousYearsData, setPreviousYearsData] = useState<
    TrialBalanceAccountDto[]
  >([]);
  const [tableData, setTableData] = useState<TableValuesDto | null>(null);
  const [structure, setStructure] = useState<AccountType[]>([]);

  const [type, setType] = useState<string>("Alle");
  const [area, setArea] = useState<string>("Samlet");

  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    if (
      currentData.length === 0 ||
      previousYearsData.length === 0 ||
      !structure
    )
      return;
    const availablePeriods = generatePeriodsForYear(undefined, true).filter(
      (item) => item.includes("Q") || item.includes("Y")
    );

    const allData = [...currentData, ...previousYearsData];

    let values = structure
      .filter((item) => hasValuesForAccountTypeOrCaption(allData, item.name))
      .map((item) => {
        let row = {
          name: item.nameEnglish,
          highlight: true,
          values: availablePeriods.map((period) => 0),
        };

        let accountClassRows = item.accountClasses
          .filter((item) =>
            hasValuesForAccountTypeOrCaption(allData, item.name)
          )
          .map((accountClass) => {
            let accClassRow = {
              name: accountClass.nameEnglish ?? "",
              highlight: false,
              values: availablePeriods.map((period) =>
                calculateAmountByPeriodAndAccount(
                  allData,
                  period,
                  [accountClass.name],
                  "profit"
                )
              ),
            };
            return accClassRow;
          });
        return [row, ...accountClassRows];
      });

    const latestQuarter = getLatestQuarter([...availablePeriods]);
    const currentYear = `YTD ${latestQuarter.slice(3)}`;

    let tableData = {
      accountingPeriods: availablePeriods.map((period) => ({
        name: period,
        highlight: [latestQuarter, currentYear].includes(period) ? true : false,
      })),
      values: values.flat(),
      title: "Profit and loss",
      comments: [],
    };

    let EBIT = {
      name: "EBIT",
      highlight: true,
      values: availablePeriods.map((period) =>
        calculateEBITByPeriod(allData, period)
      ),
    };

    const depreciationIndex = tableData.values.findIndex(
      (item) => item.name === "Financial expenses"
    );
    if (depreciationIndex !== -1)
      tableData.values.splice(depreciationIndex, 0, EBIT);

    let pretaxIncome = {
      name: "Pretax income",
      highlight: true,
      values: availablePeriods.map((period) =>
        calculatePretaxIncomeByPeriod(allData, period)
      ),
    };

    const finansInntekterIndex = tableData.values.findIndex(
      (item) => item.name === "Finanskostnader"
    );
    if (finansInntekterIndex !== -1)
      tableData.values.splice(finansInntekterIndex, 0, EBIT);

    tableData.values.push(pretaxIncome);
    setTableData(tableData);
  }, [currentData, previousYearsData, structure]);

  useEffect(() => {
    async function fetchData() {
      try {
        const [currentPeriod, firstPeriod] = ["202412", "202401"];
        //const [currentPeriod, firstPeriod] = getCurrentAndFirstPeriodOfYear();
        //const revenueAccounts = ["3059"];
        const revenueAccounts = ["3000", "3010", "3059", "3900"];
        const clients = ["Grieg Connect AS"];

        var parameters: TransactionQuery = {
          periodFrom: firstPeriod,
          periodTo: currentPeriod,
          accounts: revenueAccounts,
          clients: clients,
        };

        const [current, first] = getCurrentAndFirstPeriodOfYear();
        const [previousCurrent, previousFirst] = getPreviousYearPeriods();

        setLoading(true);

        const [currentSet, previousSet, accountingStructure] =
          await Promise.all([
            GetTrialBalance({
              periodFrom: first,
              periodTo: current,
              clients: clients,
              mappingCategory: 1,
              includeBalanceSheet: false,
              includeProfitLoss: true,
            }),
            GetTrialBalance({
              periodFrom: previousFirst,
              periodTo: previousCurrent,
              clients: clients,
              mappingCategory: 1,
              includeBalanceSheet: false,
              includeProfitLoss: true,
            }),
            GetAccountingStructure(),
          ]);

        setStructure(accountingStructure);
        setCurrentData(currentSet);
        setPreviousYearsData(previousSet);

        const data = await GetRevenuePerProduct(parameters);
        parameters.accounts = ["3129"];
        const otherRevenue = await GetRevenuePerProduct(parameters);
        otherRevenue.forEach((item) => {
          item.groupArea = "Accrued revenue";
          item.groupType = "Accrued revenue";
        });
        data.push(...otherRevenue);
        setProducts(data);
        let vendors = Array.from(new Set(data.map((item) => item.customer)));
        setDistinctVendors(vendors);
        setDistinctVendors(
          Array.from(new Set(data.map((item) => item.customer))).filter(
            (item) => item !== ""
          ) // Remove empty string
        );
        setDistinctProducts(
          Array.from(new Set(data.map((item) => item.groupArea))).filter(
            (item) => item !== ""
          ) // Remove empty string
        );
        setDistinctPeriods(Array.from(new Set(data.map((item) => item.month))));
        setDistinctAreas(
          Array.from(new Set(data.map((item) => item.groupArea))).filter(
            (item) => item !== "" && item !== "Accrued revenue"
          ) // Remove empty string and accrued revenue
        );
      } catch (error) {
        console.error("Error fetching revenue data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (!products || products.length === 0 || !tableData) return;
    const data = createPowerpointDataset();

    setReport(data);
  }, [products, tableData, distinctPeriods]);

  function createPowerpointDataset(): PowerpointReportDto {
    const licenseGroupAreas = ["Subscription revenues"];
    const otherGroups = [
      "Other revenues",
      "Delivery and consultancy",
      "Accrued revenue",
    ];

    let licenses = products.filter((item) =>
      licenseGroupAreas.includes(item.groupType)
    );

    const report: PowerpointReportDto = {
      entity: "Grieg Connect AS",
      unit: "TNOK",
      description: "",
      date: moment(new Date()).format("LL"),
      lineCharts: [],
      tables: tableData ? [tableData] : [],
    };

    const areas: LineChartValuesDto[] = distinctAreas
      //.filter((area) => !["", "Security", "Annet"].includes(area))
      .map((area) => ({
        values: distinctPeriods.map((period) =>
          licenses
            .filter((item) => item.month === period && item.groupArea === area)
            .reduce((value, product) => (value += product.amount), 0)
        ),
        title: `Monthly recurring revenue - ${area}`,
        periods: distinctPeriods.map((item) => getMonthName(item)),
        axisTitle: "NOK",
        comments: [],
      }));

    const total: LineChartValuesDto = {
      title: "Monthly recurring revenue - All licenses",
      axisTitle: "NOK",
      periods: distinctPeriods.map((item) => getMonthName(item)),
      values: distinctPeriods.map((period) =>
        licenses
          .filter((item) => item.month === period)
          .reduce((value, product) => (value += product.amount), 0)
      ),
      comments: [],
    };

    report.lineCharts = [total, ...areas];
    return report;
  }

  async function handleDownload() {
    const report = createPowerpointDataset();
    DownloadGriegConnectPresentation(report);
  }

  function calculateDataset() {
    let filteredData = [...products];

    switch (type) {
      case "Lisenser":
        filteredData = filteredData.filter(
          (item) => item.groupType === "Subscription revenues"
        );
        break;
      case "Tjenester":
        filteredData = filteredData.filter(
          (item) => item.groupType !== "Subscription revenues"
        );
        break;
      case "Alle":
        break;
      default:
        break;
    }

    switch (area) {
      case "Per type":
        let dataSet = distinctAreas.map((area) => ({
          values: distinctPeriods.map((period) =>
            filteredData
              .filter(
                (item) => item.month === period && item.groupArea === area
              )
              .reduce((value, product) => (value += product.amount), 0)
          ),
          name: area === "" ? "Ingen mapping" : area,
        }));
        setData(dataSet);
        break;
      case "Samlet":
        let dataSet2 = [
          {
            values: distinctPeriods.map((period) =>
              filteredData
                .filter((item) => item.month === period)
                .reduce((value, product) => (value += product.amount), 0)
            ),
            name: "Samlet",
          },
        ];
        setData(dataSet2);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    calculateDataset();
  }, [products, type, area, distinctPeriods]);

  useEffect(() => {
    let allProducts = [...products];
    switch (type) {
      case "Lisenser":
        allProducts = allProducts.filter(
          (item) => item.groupType === "Subscription revenues"
        );
        break;
      case "Tjenester":
        allProducts = allProducts.filter(
          (item) => item.groupType !== "Subscription revenues"
        );
        break;
      case "Alle":
        break;
      default:
        break;
    }

    let vendors = distinctVendors
      .map((vendor) => ({
        values: distinctPeriods.map((period) =>
          allProducts
            .filter((item) => item.month === period && item.customer === vendor)
            .reduce((value, item) => (value += item.amount), 0)
        ),
        name: vendor,
        total: allProducts
          .filter((item) => item.customer === vendor)
          .reduce((value, item) => (value += item.amount), 0),
      }))
      .sort((a, b) => b.total - a.total);

    let values = distinctProducts
      .map((product) => ({
        values: distinctPeriods.map((period) =>
          allProducts
            .filter(
              (item) => item.month === period && item.groupArea === product
            )
            .reduce((value, item) => (value += item.amount), 0)
        ),
        name: product,
        total: allProducts
          .filter((item) => item.groupArea === product)
          .reduce((value, item) => (value += item.amount), 0),
      }))
      .sort((a, b) => b.total - a.total);

    setProductValues(values);
    setVendorValues(vendors);
  }, [distinctProducts, products, distinctPeriods, type, distinctVendors]);

  const getMonthName = (month: number): string => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthNames[month - 1];
  };

  if (loading) {
    return (
      <Grid item container xs={12} spacing={2} sx={{ padding: 3 }}>
        <Grid item xs={12}>
          <LineChartLoader />
        </Grid>
        <Grid item xs={12}>
          <SkeletonTable />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid item container xs={12} sx={{ padding: 2 }}>
      <Grid item container xs={12} spacing={2}>
        <Grid item container xs={12} md={7} spacing={2}>
          <Grid item>
            <ButtonGroup size="small">
              {["Lisenser", "Tjenester", "Alle"].map((item) => (
                <Button
                  key={item}
                  sx={{ fontSize: "0.7rem", width: 85 }}
                  onClick={() => setType(item)}
                  variant={item === type ? "contained" : "outlined"}
                >
                  {item}
                </Button>
              ))}
            </ButtonGroup>
          </Grid>
          <Grid item>
            <ButtonGroup size="small">
              {["Per type", "Samlet"].map((item) => (
                <Button
                  key={item}
                  sx={{ fontSize: "0.7rem", width: 85 }}
                  onClick={() => setArea(item)}
                  variant={item === area ? "contained" : "outlined"}
                >
                  {item}
                </Button>
              ))}
            </ButtonGroup>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={5}
          justifyContent={"right"}
          spacing={2}
        >
          <Grid item>
            <FormGroup>
              <FormControlLabel
                label="Vis detaljer"
                slotProps={{ typography: { fontSize: "0.7rem" } }}
                control={
                  <Switch
                    value={showDetails}
                    onChange={(event) => setShowDetails(event.target.checked)}
                  />
                }
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormControl
              variant="outlined"
              style={{ minWidth: 120, marginBottom: 20 }}
            >
              <InputLabel id="details-mode-label">Type</InputLabel>
              <Select
                size="small"
                labelId="details-mode-label"
                value={detailsMode}
                onChange={(e) =>
                  setDetailsMode(e.target.value as "vendor" | "product")
                }
                label="Detaljer"
                sx={{ fontSize: "0.7rem" }}
              >
                <MenuItem sx={{ fontSize: "0.7rem" }} value="product">
                  Område
                </MenuItem>
                <MenuItem sx={{ fontSize: "0.7rem" }} value="vendor">
                  Kunde
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Tooltip title="Last ned presentasjon">
              <span>
                {/* //add span in order to use tooltip when button is disabled */}
                <IconButton
                  disabled={!products || products.length === 0}
                  onClick={(e) => handleDownload()}
                >
                  <PowerpointIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Rediger presentasjon">
              <IconButton size="small" onClick={() => handleOpen()}>
                <Edit />
              </IconButton>
            </Tooltip>
          </Grid>
          {report && (
            <EditPowerpointModal
              report={report}
              open={open}
              onClose={handleClose}
              onSave={handleSave}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <LineChart
            data={data}
            height={100}
            labels={distinctPeriods.map((item) => getMonthName(item))}
            title="Inntekter per måned"
          />
        </Grid>
        <Grid item xs={12}>
          <Collapse in={showDetails}>
            {(detailsMode === "product" ? productValues : vendorValues)
              .length === 0 ? (
              <Typography sx={{ marginLeft: 10 }} variant="body2">
                Ingen data funnet for nåværende periode.
              </Typography>
            ) : (
              <Grid item xs={12}>
                <TableContainer sx={{ maxHeight: 350 }}>
                  <Table size="small" stickyHeader>
                    <TableHead>
                      <TableRow
                        sx={{
                          th: {
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: "bold",
                            fontSize: "0.8rem",
                            padding: "10px",
                            borderBottom: "1px solid",
                            borderBottomColor: "lightgray",
                          },
                        }}
                      >
                        <TableCell>
                          {detailsMode === "product" ? "Product" : "Customer"}
                        </TableCell>
                        {distinctPeriods.map((period) => (
                          <TableCell align="right" key={period}>
                            {getMonthName(period)}
                          </TableCell>
                        ))}
                        <TableCell align="right" key={"Total"}>
                          Total
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(detailsMode === "product"
                        ? productValues
                        : vendorValues
                      ).map((product) => (
                        <>
                          <TableRow
                            key={product.name}
                            sx={{
                              td: {
                                fontFamily: "Poppins, sans-serif",
                                fontSize: "0.7rem",
                                padding: "10px",
                                borderBottom: "none",
                              },
                            }}
                          >
                            <TableCell>
                              {product.name === ""
                                ? "Ingen mapping"
                                : product.name}
                            </TableCell>
                            {product.values.map((periodValue) => (
                              <TableCell sx={{ width: "100px" }} align="right">
                                {formatNumberInThousands(periodValue)}
                              </TableCell>
                            ))}
                            <TableCell sx={{ width: "100px" }} align="right">
                              {formatNumberInThousands(product.total)}
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                      <TableRow
                        key="Sum"
                        sx={{
                          backgroundColor: "secondary.main",
                          td: {
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "0.7rem",
                            padding: "10px",
                            borderBottom: "none",
                            fontWeight: "bold",
                            color: "primary.contrastText",
                          },
                        }}
                      >
                        <TableCell align="left">Sum</TableCell>
                        {distinctPeriods.map((period, index) => (
                          <TableCell align="right" key={period}>
                            {formatNumberInThousands(
                              (detailsMode === "product"
                                ? productValues
                                : vendorValues
                              ).reduce(
                                (value, item) => (value += item.values[index]),
                                0
                              )
                            )}
                          </TableCell>
                        ))}
                        <TableCell align="right" key={"Totalt"}>
                          {formatNumberInThousands(
                            (detailsMode === "product"
                              ? productValues
                              : vendorValues
                            ).reduce((value, item) => (value += item.total), 0)
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}
            <Grid item xs={12}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow></TableRow>
                  </TableHead>
                  <TableBody>{}</TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RevenueTable;
