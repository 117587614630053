import {
  Button,
  Chip,
  Collapse,
  Divider,
  Grid,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/system";
import { useEffect, useState } from "react";
import { Client, GetClients } from "../../api/GetClients";
import {
  GetTrialBalance,
  TrialBalanceAccountDto,
} from "../../api/GetTrialBalance";
import { ReportingTable } from "../../components/Reporting/ReportingTable";
import SkeletonTable from "../../components/SkeletonTable";
import {
  getCurrentAndFirstPeriodOfYear,
  getPreviousYearPeriodFromString,
  getPreviousYearPeriods,
} from "../../utils/timePeriods";
import { generatePeriodsForYear } from "../../utils/trialBalanceCalculations";

const clientsForCamilla = [
  "Pania As",
  "Gran Sasso AS",
  "Bjørnegløtt AS",
  "Hallingskarvet As",
];
const clientsForPer = [
  "Kvasshøgdi AS",
  "Benedicte Wg AS",
  "Joachim Wg AS",
  "Nina Wg AS",
  "Thomas Wg AS",
  "Ragnhild Wg As",
  "Grieg Bryggen Holding AS",
  "Kvasshøgdi Eiendom AS",
  "Ystholmen Felles AS",
];
const clientsForElisabeth = [
  "A/S Polycorp",
  "Acumulus As",
  "Klar Invest As",
  "Maneo Holding As",
  "Nyhavn Investment As",
  "Salrhavn AS",
  "Salthavn Invest As",
  "Vitare As",
  "Volante Invest AS",
];
const clientsForElnaKathrine = [
  "Sulefjell AS",
  "GriegSuletind Invest AS",
  "Cipieres AS",
  "Cipieres II AS",
  "Col De Vence AS",
  "Col de Vence II AS",
  "La Vanade AS",
  "La Vanade II AS",
  "Madone AS",
];

type ProfitLossProps = {
  showColumnsAs: "clients" | "periods";
  showFamilyOfficeClients: boolean;
  clients?: string[];
  language?: "no" | "en";
  showSummaryLines?: boolean;
  showHeaders?: boolean;
};

export default function ProfitLoss({
  showColumnsAs,
  showFamilyOfficeClients,
  clients,
  language = "no",
  showSummaryLines = true,
  showHeaders = true,
}: ProfitLossProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<TrialBalanceAccountDto[]>([]);
  const [comparisonData, setComparisonData] = useState<
    TrialBalanceAccountDto[]
  >([]);
  const [selectedPeriods, setSelectedPeriods] = useState<PeriodSelection[]>([]);
  const [selectedClients, setSelectedClients] = useState<string[]>(
    clients ?? []
  );
  const [periods, setPeriods] = useState<string[]>([]);
  const [showFilters, setShowFilters] = useState<boolean>(true);

  const [displayMode, setDisplayMode] = useState<"clients" | "periods">(
    showColumnsAs
  );

  const handleToggleFilters = () => {
    setShowFilters((state) => !state);
  };

  const loadData = async () => {
    try {
      if (selectedClients.length === 0) return;

      const [current, first] = getCurrentAndFirstPeriodOfYear();
      const [previousCurrent, previousFirst] = getPreviousYearPeriods();

      setLoading(true);

      const [currentSet, previousSet] = await Promise.all([
        GetTrialBalance({
          periodFrom: first,
          periodTo: current,
          clients: selectedClients,
          mappingCategory: 1,
          includeBalanceSheet: false,
          includeProfitLoss: true,
        }),
        GetTrialBalance({
          periodFrom: previousFirst,
          periodTo: previousCurrent,
          clients: selectedClients,
          mappingCategory: 1,
          includeBalanceSheet: false,
          includeProfitLoss: true,
        }),
      ]);
      setLoading(true);
      setData(currentSet);
      setComparisonData(previousSet);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const periods: string[] = [];
    selectedPeriods.forEach((period) => {
      switch (period.state) {
        case "selected":
          periods.push(period.name);
          break;
        case "selected-with-comparison-figures":
          periods.push(period.name);
          periods.push(getPreviousYearPeriodFromString(period.name));
          break;
        default:
          break;
      }
      setPeriods(periods);
    });
  }, [selectedPeriods]);

  const availablePeriods = generatePeriodsForYear();

  const onPeriodSelected = (selectedPeriods: PeriodSelection[]) => {
    setSelectedPeriods(selectedPeriods);
  };

  const onClientSelected = (selectedClients: string[]) => {
    if (showFamilyOfficeClients) setSelectedClients(selectedClients);
  };

  return (
    <Grid item container xs={12} spacing={2}>
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12}>
          <Collapse in={showFilters}>
            <PeriodSelector
              showFamilyOfficeClients={showFamilyOfficeClients}
              periods={availablePeriods}
              onClientSelected={onClientSelected}
              onPeriodSelected={onPeriodSelected}
              selection="period"
            />
          </Collapse>
        </Grid>
        <Grid item xs={3}>
          <Select
            fullWidth
            size="small"
            value={displayMode}
            onChange={(e) =>
              setDisplayMode(e.target.value as "clients" | "periods")
            }
          >
            <MenuItem value="clients">Klient</MenuItem>
            <MenuItem value="periods">Periode</MenuItem>
          </Select>
        </Grid>
        <Grid item container xs={6} justifyContent={"center"} spacing={2}>
          <Grid item>
            <Button
              disabled={selectedClients.length === 0}
              variant="contained"
              color="primary"
              onClick={loadData}
            >
              Hent data
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleToggleFilters}
            >
              {`${showFilters ? "Skjul" : "Vis"} filtre`}
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>

      <Grid item xs={12}>
        {loading ? (
          <SkeletonTable rows={6} cols={5} />
        ) : (
          <ReportingTable
            type="profit"
            language={language}
            periods={periods}
            data={data}
            showHeaders={showHeaders}
            showSummaryLines={showSummaryLines}
            comparisonData={comparisonData}
            showColumnsAs={displayMode}
            clients={selectedClients}
          />
        )}
      </Grid>
    </Grid>
  );
}

export type PeriodSelection = {
  name: string;
  state: "selected" | "selected-with-comparison-figures" | "unselected";
};

type PeriodSelectorProps = {
  periods: string[];
  onPeriodSelected: (selectedPeriods: PeriodSelection[]) => void;
  onClientSelected: (selectedClients: string[]) => void;
  selection: "client" | "period";
  showFamilyOfficeClients: boolean;
};

export function PeriodSelector({
  periods,
  onPeriodSelected,
  onClientSelected,
  selection,
  showFamilyOfficeClients,
}: PeriodSelectorProps) {
  const [periodsSelected, setPeriodsSelected] = useState<PeriodSelection[]>(
    periods.map((item) => ({ name: item, state: "unselected" }))
  );

  const [clients, setClients] = useState<Client[]>([]);
  const [selectedClients, setSelectedClients] = useState<string[]>([]);

  const theme = useTheme();

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  function handleChipClick(period: PeriodSelection) {
    setPeriodsSelected((state) => {
      const newState: PeriodSelection[] = state.map((item) => {
        if (item.name === period.name) {
          switch (item.state) {
            case "selected":
              return {
                name: item.name,
                state: "selected-with-comparison-figures",
              };
            case "unselected":
              return { name: item.name, state: "selected" };
            case "selected-with-comparison-figures":
              return { name: item.name, state: "unselected" };
            default:
              return item;
          }
        }
        return item;
      });
      return newState;
    });
  }

  useEffect(() => {
    onPeriodSelected(periodsSelected);
  }, [periodsSelected]);

  useEffect(() => {
    onClientSelected(selectedClients);
  }, [selectedClients]);

  const selectMonths = () => {
    setPeriodsSelected((state) => {
      return state.map((item) => {
        if (months.includes(item.name.substring(0, 3))) {
          return { name: item.name, state: "selected" };
        }
        return { name: item.name, state: "unselected" };
      });
    });
  };

  const selectQuarters = () => {
    setPeriodsSelected((state) => {
      return state.map((item) => {
        if (item.name.includes("Q")) {
          return { name: item.name, state: "selected" };
        }
        return { name: item.name, state: "unselected" };
      });
    });
  };

  const resetPeriods = () => {
    setPeriodsSelected((state) => {
      return state.map((item) => {
        return { name: item.name, state: "unselected" };
      });
    });
  };

  const onSelectClient = (client: string) => {
    setSelectedClients((state) => {
      if (state.includes(client)) return state.filter((p) => p !== client);
      return [...state, client];
    });
  };

  const handleClientGroupSelection = (group: string) => {
    switch (group) {
      case "Camilla":
        setSelectedClients(clientsForCamilla);
        break;
      case "Per":
        setSelectedClients(clientsForPer);
        break;
      case "Elna-Kathrine":
        setSelectedClients(clientsForElnaKathrine);
        break;
      case "Elisabeth":
        setSelectedClients(clientsForElisabeth);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        let data = await GetClients([
          "Family Office Bergen domene",
          "Family Office Oslo domene",
        ]);
        setClients(data);
      } catch (e) {
        console.log(e);
      } finally {
      }
    };

    selectMonths();
    loadData();
  }, []);

  return (
    <Grid item container xs={12} spacing={1}>
      <Grid item container xs={12} spacing={2}>
        <>
          <Grid item xs={12}>
            <Typography variant="h6">Periode</Typography>
            <Divider />
          </Grid>

          <Grid item container xs={12} sm={9} spacing={1}>
            <Grid item container spacing={1} xs={12}>
              {periodsSelected
                .filter(
                  (period) =>
                    !period.name.includes("Q") && !period.name.includes("Y")
                )
                .map((period) => (
                  <Grid item>
                    <Chip
                      key={period.name}
                      label={period.name}
                      clickable
                      onClick={() => handleChipClick(period)}
                      style={{
                        backgroundColor:
                          period.state === "selected"
                            ? theme.palette.primary.main
                            : period.state ===
                              "selected-with-comparison-figures"
                            ? theme.palette.secondary.main
                            : undefined,
                        color:
                          period.state === "selected" ||
                          period.state === "selected-with-comparison-figures"
                            ? theme.palette.primary.contrastText
                            : undefined,
                      }}
                    />
                  </Grid>
                ))}
            </Grid>
            <Grid item container spacing={1} xs={12}>
              {periodsSelected
                .filter((period) => period.name.includes("Q"))
                .map((period) => (
                  <Grid item>
                    <Chip
                      key={period.name}
                      label={period.name}
                      clickable
                      onClick={() => handleChipClick(period)}
                      style={{
                        backgroundColor:
                          period.state === "selected"
                            ? theme.palette.primary.main
                            : period.state ===
                              "selected-with-comparison-figures"
                            ? theme.palette.secondary.main
                            : undefined,
                        color:
                          period.state === "selected" ||
                          period.state === "selected-with-comparison-figures"
                            ? theme.palette.primary.contrastText
                            : undefined,
                      }}
                    />
                  </Grid>
                ))}
            </Grid>
            <Grid item container spacing={1} xs={12}>
              {periodsSelected
                .filter((period) => period.name.includes("Y"))
                .map((period) => (
                  <Grid item>
                    <Chip
                      key={period.name}
                      label={period.name}
                      clickable
                      onClick={() => handleChipClick(period)}
                      style={{
                        backgroundColor:
                          period.state === "selected"
                            ? theme.palette.primary.main
                            : period.state ===
                              "selected-with-comparison-figures"
                            ? theme.palette.secondary.main
                            : undefined,
                        color:
                          period.state === "selected" ||
                          period.state === "selected-with-comparison-figures"
                            ? theme.palette.primary.contrastText
                            : undefined,
                      }}
                    />
                  </Grid>
                ))}
            </Grid>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Grid
              container
              spacing={1}
              direction={"column"}
              alignContent={"flex-end"}
            >
              <Grid item>
                <Button
                  sx={{ minWidth: 160 }}
                  size="small"
                  variant="outlined"
                  onClick={selectMonths}
                >
                  SELECT MONTHS
                </Button>
              </Grid>
              <Grid item>
                <Button
                  sx={{ minWidth: 160 }}
                  size="small"
                  variant="outlined"
                  onClick={selectQuarters}
                >
                  SELECT QUARTERS
                </Button>
              </Grid>
              <Grid item>
                <Button
                  sx={{ minWidth: 160 }}
                  variant="outlined"
                  size="small"
                  color="warning"
                  onClick={() => resetPeriods()}
                >
                  CLEAR
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>

        {showFamilyOfficeClients && (
          <>
            <Grid item xs={12}>
              <Typography variant="h6">Klienter</Typography>
              <Divider />
            </Grid>
            <Grid item container xs={12} spacing={1}>
              <Grid
                item
                container
                xs={12}
                sm={9}
                spacing={1}
                justifyContent={"flex-start"}
                alignContent={"flex-start"}
              >
                {clients.map((client) => (
                  <Grid item>
                    <Chip
                      key={client.name}
                      label={client.name}
                      clickable
                      onClick={() => onSelectClient(client.name)}
                      style={{
                        backgroundColor: selectedClients.includes(client.name)
                          ? theme.palette.primary.main
                          : undefined,
                        color: selectedClients.includes(client.name)
                          ? theme.palette.primary.contrastText
                          : undefined,
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
              <Grid
                item
                container
                xs={12}
                sm={3}
                spacing={1}
                alignContent={"flex-end"}
                direction={"column"}
              >
                <Grid item>
                  <Button
                    sx={{ minWidth: 160 }}
                    size="small"
                    variant="outlined"
                    onClick={() => handleClientGroupSelection("Camilla")}
                  >
                    Camilla
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    sx={{ minWidth: 160 }}
                    size="small"
                    variant="outlined"
                    onClick={() => handleClientGroupSelection("Per")}
                  >
                    Per
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    sx={{ minWidth: 160 }}
                    size="small"
                    variant="outlined"
                    onClick={() => handleClientGroupSelection("Elna-Kathrine")}
                  >
                    Elna-Kathrine
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    sx={{ minWidth: 160 }}
                    size="small"
                    variant="outlined"
                    onClick={() => handleClientGroupSelection("Elisabeth")}
                  >
                    Elisabeth
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    sx={{ minWidth: 160 }}
                    size="small"
                    variant="outlined"
                    color="warning"
                    onClick={() => setSelectedClients([])}
                  >
                    Clear
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
}
